<template>
  <div
    id="mediClassModal"
    tabindex="-1"
    role="dialog"
    aria-modal="true"
    aria-labelledby="mediClassModalLabel"
    aria-describedby="mediClassModalDescription"
    class="fixed left-0 right-0 top-0 z-50 flex h-full max-h-full w-full items-center justify-center overflow-y-auto overflow-x-hidden bg-gray-900 bg-opacity-30 p-4 md:inset-0">
    <div class="relative max-h-full w-full max-w-md">
      <!-- Modal content -->
      <div class="relative rounded-lg bg-white shadow">
        <div class="flex flex-col gap-4 p-6">
          <h3 id="mediClassModalLabel" class="mb-2 text-3xl leading-tight text-gray-900">mediClass ist jetzt haelsi</h3>
          <p id="mediClassModalDescription" class="text-base text-gray-800">
            Gesundheit ist für dich gerade einfacher und moderner geworden. Wir verabschieden uns von der Marke
            mediClass und sagen: Hallo, haelsi - dein hybrides Gesundheitszentrum.
          </p>
          <div class="mb-6 mt-3 flex justify-center">
            <MediclassIsNowHaelsiIllustration aria-label="hidden" class="w-[60%]" />
          </div>

          <button
            type="button"
            class="inline-flex items-center justify-center gap-1 rounded-full bg-yellow p-2 font-medium text-gray-900 transition hover:bg-yellow-300 focus:border-yellow-700"
            data-modal-hide="mediClassModal"
            data-modal-target="mediClassModal"
            @click="emit('closeModal')">
            Weiter zu haelsi
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import MediclassIsNowHaelsiIllustration from "assets/svg/mediclassIsNowHaelsiIllustration.svg?skipsvgo";

const emit = defineEmits(["closeModal"]);
</script>
